@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap);

.form-box {
  background: url(https://i.imgur.com/73BxBuI.png);
  background-size: cover;
  background-position: center;
}

.header-modal {
  margin-bottom: 20px;
}

.form-crud {
  margin: 0 auto;
}

.form-crud .form-control {
  border-radius: 0;
  margin-bottom: 10px;
}

.form-crud .form-group {
  position: relative;
}

.form-crud .btn {
  border-radius: 0;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.form-crud .btn.btn-primary {
  background: #3bc3ff;
  border-color: #31c0ff;
}

.form-crud .btn.btn-danger {
  background: #fa2222;
  border-color: #fa2222;
}

.crud-icon {
  font-size: xx-large;
  color: #31c0ff;
  font-weight: bold;
}

.crud-judul {
  font-size: xx-large;
  color: #000;
  font-weight: bold;
}

.crud-header {
  font-size: xx-large !important;
  color: #31c0ff !important;
  font-weight: bold !important;
}

.silang {
  font-size: xx-large !important;
  border: transparent !important;
  background: transparent !important;
  font-weight: bold !important;
  color: #000 !important;
}

.silang:hover {
  border: transparent !important;
  background: transparent !important;
  color: #fa2222 !important;
  font-weight: bold !important;
}

.crud-label {
  font-size: medium !important;
  font-weight: bold !important;
  color: black !important;
}

.modal-map {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  min-height: 300px !important;
}
