.table-responsive {
  margin: 0px 0;
}
.table-wrapper {
  background: #fff;
  padding: 20px 25px;
  border-radius: 3px;
  min-width: 1000px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.table-title {
  padding-bottom: 15px;
  background-image: linear-gradient(to right, #03045e, #00b4d8, #90e0ef);
  color: #fff;
  padding: 16px 30px;
  min-width: 100%;
  margin: -20px -25px 10px;
  border-radius: 3px 3px 0 0;
}
.table-title h2 {
  margin: 5px 0 0;
  font-size: 24px;
}
.table-title .btn-group {
  float: right;
}
.table-title .btn {
  color: #fff;
  float: right;
  font-size: 13px;
  border: none;
  min-width: 50px;
  border-radius: 2px;
  border: none;
  outline: none !important;
  margin-left: 10px;
}
.table-title .btn i {
  float: left;
  font-size: 21px;
  margin-right: 5px;
}
.table-title .btn span {
  float: left;
  margin-top: 2px;
}
table.table tr th {
  border-color: #e9e9e9;
  padding: 7px;
  vertical-align: middle;
}
table.table tr td {
  border-color: #e9e9e9;
  padding: 12px 15px;
  vertical-align: middle;
}
table.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fcfcfc;
}
table.table-striped.table-hover tbody tr:hover {
  background: #f5f5f5;
}
table.table th i {
  font-size: 13px;
  margin: 0 5px;
  cursor: pointer;
}
table.table td a {
  font-weight: bold;
  color: #566787;
  display: inline-block;
  text-decoration: none;
  outline: none !important;
}
table.table td a:hover {
  color: #2196f3;
}
.detail {
  color: #2fa4ff !important;
  border-style: hidden !important;
  background-color: transparent !important;
}
.detail:hover {
  color: #0e185f !important;
  border-style: hidden !important;
  background-color: transparent !important;
}
.edit {
  color: #ffe61b !important;
  border-style: hidden !important;
  background-color: transparent !important;
}
.edit:hover {
  color: #ffb72b !important;
  border-style: hidden !important;
  background-color: transparent !important;
}
.delete {
  color: #ff0000 !important;
  border-style: hidden !important;
  background-color: transparent !important;
}
.delete:hover {
  color: #950101 !important;
  border-style: hidden !important;
  background-color: transparent !important;
}
.close {
  color: #000000 !important;
  border-style: hidden !important;
  background-color: transparent !important;
}
.close:hover {
  color: #f44336 !important;
  border-style: hidden !important;
  background-color: transparent !important;
}
table.table td i {
  font-size: 19px;
}
table.table .avatar {
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 10px;
}
.pagination {
  float: right;
  margin: 0 0 5px;
}
.pagination li a {
  border: none;
  font-size: 13px;
  min-width: 30px;
  min-height: 30px;
  color: #999;
  margin: 0 2px;
  line-height: 30px;
  border-radius: 2px !important;
  text-align: center;
  padding: 0 6px;
}
.pagination li a:hover {
  color: #666;
}
.pagination li.active a,
.pagination li.active a.page-link {
  background: #03a9f4;
}
.pagination li.active a:hover {
  background: #0397d6;
}
.pagination li.disabled i {
  color: #ccc;
}
.pagination li i {
  font-size: 16px;
  padding-top: 6px;
}
.hint-text {
  float: left;
  margin-top: 10px;
  font-size: 13px;
}
