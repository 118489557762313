.footer-link {
  color: white;
}

.nav-operator {
  display: block;
  padding: 0.5rem 1rem;
  color: #000000;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-operator:hover,
.nav-operator:focus {
  color: #404cfa;
}

.aktif {
  box-sizing: border-box;
  background: #2c67e9 !important;
  color: #fff !important;
  border-radius: 50px;
}

.bg-gray {
  background-color: rgb(235, 233, 233);
}

.bg-gray-banget {
  background-color: rgb(189, 189, 189);
}

.content {
  flex: 1 0 auto;
  width: 100%;
}

.tinggi-content {
  min-height: 100vh !important;
}

.tinggi-setting {
  min-height: 80vh !important;
}

small {
  font-size: 68%;
}

@media screen and (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }
}
