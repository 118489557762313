*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

.bg-auth {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


html {
  height: 100%;
}

.bg-auth {
  height: 100%;
  position: relative;
}
.bg-auth:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #2a5555;
  opacity: 0.7;
  z-index: 1;
}

video.bg-video {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: 0;
}

@media (pointer: coarse) and (hover: none) {
  .bg-auth {
    background: url("../../assets/image/bg-mobile-fallback.jpg") #2a5555 no-repeat center center scroll;
    background-size: cover;
  }

  .bg-auth video {
    display: none;
  }
}
.pesan-error {
  color: red;
}
.posisi-tengah {
  position: absolute;
  z-index: 1;
  justify-content: center;
  margin: auto;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
}
.konten-login {
  width: 400px;
  justify-content: center;
  align-content: center;
  padding: 20px;
}
.konten-registrasi {
  width: 450px;
  justify-content: center;
  align-content: center;
  padding: 20px;
}
.konten-lupa-password {
  width: 400px;
  justify-content: center;
  align-content: center;
  padding: 20px;
}
.tinggi-80 {
  min-height: 80vh;
}
