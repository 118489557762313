#beranda {
  min-height: 100vh;
}
#rute {
  min-height: 100vh;
}
#kontak {
  min-height: 100vh;
}
.dropdown-os {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.dropdown-os a:hover {background-color: #2936f031}
.dropdown-os a {
  color: black;
  padding: 12px 10px;
  text-decoration: none;
  display: block;
}
.dropdown-link:hover .dropdown-os {
  display: block;
}
.rute-map {
  margin: 0 !important;
  padding: 0 !important;
  position: relative !important;
  width: 100%;
  height: 100%;
  min-height: 500px;
}
.rata-kanan-kiri {
  text-align: justify !important;
}

#kontak .kontak {
  color: #000000 !important;
}

#kontak .kontak:hover {
  color: #5a5858 !important;
}