.print-logo {
  width: 120px !important;
}

.print-javis {
  width: 70px !important;
}

.garis-bawah {
  border-bottom: solid 3px black;
}

.print-table {
  width: 100%;
  margin-bottom: 10px;
}

.spasi {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
  margin-left: 10px;
  margin-right: 10px;
}

#jadwal {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#jadwal td, #jadwal th {
  border: 1px solid #ddd;
  padding: 8px;
}

#jadwal tr:nth-child(even){background-color: #f2f2f2;}

#jadwal tr:hover {background-color: #ddd;}

#jadwal th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #404cfa;
  color: white;
}

.posisi-maps {
  position: relative !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}