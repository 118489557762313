.btn_wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  min-height: auto;
}
.btn_col {
  flex: 1 1 auto;
  margin: 10px;
  padding: 20px;
  padding-left: 80px;
  transition: 0.5s;
  background-size: 200% auto;
  color: #212121;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 20px #eee;
  border-radius: 50px;
}
.color {
  background-image: linear-gradient(
    to right,
    #d6e5ee 0%,
    #3081fa 51%,
    #00ec0c 100%
  );
}
.tengah {
  align-content: center;
}
.text-jadwal {
  color: black;
  font-size: xx-large;
  font-weight: bold;
}
.bg-display {
  background: linear-gradient(110deg, #d6e5ee 60%, #03a9f4 60%);
}
.display-text {
  color: black;
}
.nothing {
  list-style-type: none;
  margin-left: -20px;
}
.keterangan {
  margin-top: 50px;
  margin-bottom: 50px;
}
.display {
  margin-top: 0 !important;
  padding-top: 0 !important;
  width: 100%;
  height: 400px;
}
.logo-display {
  padding-left: 200px;
}
.data-content {
  width: 200px;
  margin: 0 !important;
  padding: 0 !important;
}
.file-preview {
  width: 200px;
  height: auto;
  margin: 0 !important;
  padding: 0 !important;
}

/* Urutan Rute */
.ratings {
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 0;
}

.ratings:before {
  content: " ";
  width: calc(100% - 100px);
  height: 1px;
  background: #3081fa;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.ratings li {
  text-align: center;
  list-style: none;
  margin: 0 20px;
  position: relative;
}

.ratings li:before {
  content: " ";
  width: 20px;
  height: 20px;
  border: solid 2px #3081fa;
  border-radius: 50%;
  display: block;
  margin: 0 auto 5px;
  background: white;
}

.ratings li.active:after {
  content: " ";
  width: 16px;
  height: 16px;
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  display: block;
  background: #3081fa;
}

/*Animasi*/
#pot {
  padding-top: 20px;
  position: absolute;
  animation: linear infinite;
  animation-name: run;
  animation-duration: 7s;
}
.halte-display {
  padding: 0 !important;
  margin: 0 !important;
  position: absolute;
}
@keyframes run {
  0% {
    left: 0;
  }
  48% {
    -webkit-transform: rotateY(0deg);
  }
  50% {
    left: calc(50% - 100px);
    -webkit-transform: rotateY(180deg);
  }
  98% {
    -webkit-transform: rotateY(180deg);
  }
  100% {
    left: 0;
    -webkit-transform: rotateY(0deg);
  }
}
