img.bus {
  width: 100px;
}

.daftarBus:hover {
  background-color: #000000;
}

.online {
  color: green !important;
}

.offline {
  color: red !important;
}

.fgh {
  margin-left: -13px;
  min-height: 300px;
}

.waiting {
  color: orange !important;
  font-weight: bold;
}

.arrive {
  color: rgba(0, 0, 255, 0.849) !important;
  font-weight: bold;
}

.print-detail {
  width: 100%;
  background-color: #f5f6fa;
}
