.container-profile {
  padding-top: 70px;
  padding-bottom: 70px;
}
.card-profile {
  width: 600px;
  max-height: 750px;
}
.img-profile {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}
.image-profile {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 7px;
  border-style: solid;
  border-color: #31c0ff;
}
.btn-profile {
  padding-left: 25px;
  padding-right: 25px;
  font-size: large;
  color: antiquewhite;
}
